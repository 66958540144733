import React from "react";
import { Container } from "reactstrap";
import KCKCatalog from "../assets/KCKCatalog.pdf";

import styles from "../styles/orientation.module.css";

const Orientation = () => {
  return (
    <section className={styles.orientationSection}>
      <Container>
        <h2>New Class/Orientation</h2>
        <hr />

        <div className={styles.orientationTable}>
          <div>
            <h4>New Class Orientation @ 9AM</h4>
            <p>January 4, 11, 18, 25</p>
            <p>February 1, 8, 15, 22</p>
            <p>March 7, 14, 21, 28</p>
            <p>April 4, 11, 18, 25</p>
            <p>May 2, 9, 16, 23, 30</p>
            <p>June 6, 13, 20, 27</p>
            <p>July 11, 18, 25</p>
            <p>August 1, 8, 15, 22, 29</p>
            <p>September 5, 12, 19, 26</p>
            <p>October 3, 10, 17, 24, 31</p>
            <p>November 7, 14, 21</p>
            <p>December 5, 12, 19</p>
          </div>
          <div>
            <h4>New Class Starts 2023</h4>
            <p>January 8, 16, 22, 29</p>
            <p>February 5, 12, 19, 26</p>
            <p>March 4, 11, 18, 25</p>
            <p>April 1, 8, 15, 22, 29</p>
            <p>May 6, 13, 20, 28</p>
            <p>June 3, 10, 17, 24</p>
            <p>July 1, 8, 15, 22, 29</p>
            <p>August 5, 12, 19, 26</p>
            <p>September 3, 9, 16, 23, 30</p>
            <p>October 7, 14, 21, 28</p>
            <p>November 4, 12, 18, 25</p>
            <p>December 2, 9, 16</p>
          </div>
        </div>
        <p>
          Please view a copy of our{" "}
          <a href={KCKCatalog} target="_blank">
            current catalog
          </a>
          .
        </p>
        <p>
          * Class Orientation is mandatory for all new students on or before
          signing the enrollment agreement. Please bring the follwing: Driver's
          License, Social Security Card, High School Diploma/GED
        </p>
        <p>
          KCK Beauty and Barber offer the following affordable programs of
          Study:
        </p>
        <ul>
          <li>
            Barber: 1200 Hours; Program Cost: <strong>$14,675.00</strong>
          </li>
          <li>
            Restricted Barber: 600 Hours; Program Cost:{" "}
            <strong>$10,975.00</strong>
          </li>
          <li>
            Cosmetology: 1200 Hours; Program Cost: <strong>$14,675.00</strong>
          </li>
          <li>
            Full Specialist: 600 Hours; Program Cost: <strong>$7,900.00</strong>
          </li>
          <li>
            Instructor: 600 Hours; Program Cost: <strong>$4,750.00</strong>
          </li>
          <li>
            Manicure: 240 Hours; Program Cost: <strong>$4,100.00</strong>
          </li>
          <li>
            Skin Care Specialist: 260 Hours; Program Cost:{" "}
            <strong>$4,270.00 </strong>
          </li>
        </ul>
        <p className={styles.removeMargin}>
          Financial Aid is available for Cosmetology, Barber, Restricted Barber,
          Full Specialist, and Instructor Students who qualify.
        </p>
        <p className={styles.removeMargin}>
          Federal School Code: <strong>042768</strong>
        </p>
        <br />
        <p className={styles.removeMargin}>
          Day and Evening Hours available to accommodate Full and Part time
          Schedules
        </p>
        <br />
        {/* <p>
					Admission office is open M-F 8:30-4:30. Please call to
					schedule a tour.
				</p> */}

        <h5>
          <u>Additional Disclosures</u>
        </h5>
        <p className={styles.removeMargin}>Reporting Period 2021:</p>
        <p className={styles.removeMargin}>Graduation Rate - 92.31%</p>
        <p className={styles.removeMargin}>Placement Rate - 60.00%</p>
        <p className={styles.removeMargin}>Licensure Rate - 100.00%</p>
        <small>
          <strong>Licensed by the Commission for Independent Education</strong>
        </small>
        <br />
        <small>
          <strong>Accredited by NACCAS</strong>
        </small>
        <br />
        <small>
          <strong>
            Approved by U.S. Department of Veteran Affairs for Veterans Training
            for Education assistance of Veterans, dependents of Veterans and
            Survivor of Veterans
          </strong>
        </small>
        <br />
        <small>
          <strong>Approved to accept Vocational Rehabilitaion Benefits</strong>
        </small>
      </Container>
    </section>
  );
};

export default Orientation;
