import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import styles from "../styles/generalInfo.module.css";

import banner from "../images/banners/admission-banner.jpg";

const GeneralInfo = () => {
	return (
		<section className={styles.generalInfoSection}>
			<Container>
				<h2>Admissions</h2>
				<hr />
				<div className={styles.banner}>
					<img src={banner} alt="KCK Admission Banner" />
				</div>
				<div className={styles.paragraph}>
					<p>
						Admissions Policy for Barber, Restricted Barber,
						Cosmetology, Full Specialist, Manicure and Skin Care
						Specialist. The School only admits students beyond the
						age of compulsory school attendance in the state of
						Florida. Students must:
					</p>
					<ul>
						<li>Be 16 years of age to attend all programs.</li>
						<li>
							Complete the Admission Application and pay
							non-refundable application fee of $50, and a
							registration fee of $100.
						</li>
						<li>Have a high school diploma or GED.</li>
						<li>
							Have a parent or legal guardian with them if they
							are under the age of 18 and both must fill out and
							sign all enrollment consent forms.
						</li>
						<li>
							Present a valid I.D., U.S. passport or birth
							certificate and Social Security Card at time of
							enrollment
						</li>
						<li>
							Transfer students must provide official transcript
							from another institution(Barber, Restricted Barber,
							Cosmetology, Full Specialist, Manicure and Skin Care
							Specialist)
						</li>
						<li>
							Attend Orientation and sign an Enrollment Agreement.
						</li>
					</ul>
				</div>
				<br />
				<br />
				<div className={styles.paragraph}>
					<p>Admissions Policy for Instructor students must:</p>
					<ul>
						<li>Have a high school diploma or GED.</li>
						<li>
							Must be a licensed Barber, Restricted Barber,
							Cosmetology, Full Specialist, Manicure or Skin Care
							Specialist.
						</li>
						<li>Must bring proof of current license.</li>
						<li>
							Complete the Admission Application and pay
							non-refundable application fee of $50, and a
							registration fee of $100
						</li>
						<li>
							Present a valid I.D., U.S. passport or birth
							certificate and Social Security Card at time of
							enrollment
						</li>
						<li>
							Attend Orientation and sign an Enrollment Agreement.
						</li>
					</ul>
				</div>
			</Container>
		</section>
	);
};

export default GeneralInfo;
